import React, { useState } from 'react';
import { images } from '../constants/images';
import { TextField } from '@mui/material';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { CONTACT } from '../api/utils';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const postContactForm = () => {
    let data = { name, email, subject, message };
    if (
      name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      message.length > 0
    ) {
      axios
        .post(CONTACT, data)
        .then(({ data }) => {
          if (data?.status === 'ok') {
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            setShowError(false);
            cogoToast.success(
              data?.data ||
                'We have received your enquiry. We will get back to you soon'
            );
          } else if (data?.status != 'ok') {
            cogoToast.error(data.errorMessage || 'Something went wrong');
          }
        })
        .catch((err) => {
          cogoToast.error(err.error || 'Something went wrong');
        });
    } else {
      setShowError(true);
      cogoToast.info('Please enter valid details');
      return;
    }
  };

  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec">
          <div className="container">
            <div className="row justify-content-center">
              <div className="row justify-content-center">
                <div className="col-lg-6 pb-5">
                  <h1 className="tc-primary head-font text-center pb-5">
                    Let's talk about your project
                  </h1>
                  <p
                    className="tc-primary fs-24"
                    style={{ textAlign: 'justify' }}
                  >
                    Contact us to discuss your app idea and possibilities. We’ll
                    advise you on the best solution and estimate the project. If
                    you have any questions – we’ll provide you with answers
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-8">
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="col-lg-6">
                    <TextField
                      fullWidth
                      label="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      error={showError ? name.length === 0 : null}
                      id="standard-error-helper-text"
                      helperText={
                        showError
                          ? name.length === 0
                            ? 'Name can not be blank'
                            : null
                          : null
                      }
                      required
                      margin="dense"
                      sx={{
                        '& input': {
                          background: '#e0e5ef',
                        },
                      }}
                      inputProps={{ maxLength: 200 }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextField
                      fullWidth
                      label="Email"
                      value={email}
                      type="email"
                      margin="dense"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      error={showError ? email.length === 0 : null}
                      id="standard-error-helper-text"
                      helperText={
                        showError
                          ? email.length === 0
                            ? 'Email can not be blank'
                            : null
                          : null
                      }
                      sx={{
                        '& input': {
                          background: '#e0e5ef',
                        },
                      }}
                      inputProps={{ maxLength: 200 }}
                    />
                  </div>
                </div>
                <TextField
                  className="mt-4"
                  fullWidth
                  label="Subject"
                  value={subject}
                  required
                  style={{ marginTop: 50 }}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  error={showError ? subject.length === 0 : null}
                  id="standard-error-helper-text"
                  helperText={
                    showError
                      ? subject.length === 0
                        ? 'Subject can not be blank'
                        : null
                      : null
                  }
                  margin="dense"
                  sx={{
                    '& input': {
                      background: '#e0e5ef',
                    },
                  }}
                  inputProps={{ maxLength: 200 }}
                />

                <TextField
                  fullWidth
                  label="Message"
                  value={message}
                  required
                  multiline
                  rows={6}
                  style={{ marginTop: 25, background: '#e0e5ef' }}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  margin="dense"
                  error={showError ? message.length === 0 : null}
                  id="standard-error-helper-text"
                  InputProps={{
                    '&.MuiFormHelperText-root.Mui-error': {
                      background: 'orange',
                    },
                  }}
                  sx={{
                    '& input': {
                      background: '#e0e5ef',
                    },
                  }}
                  inputProps={{ maxLength: 500 }}
                />

                <button
                  onClick={postContactForm}
                  type="submit"
                  className="link_btn mt-4 mx-auto"
                  style={{ marginBottom: 50 }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="bg-r-img">
          <img src={images?.footerBg} alt="" />
        </div>
        <div
          className="container"
          style={{ justifyContent: 'center', display: 'flex' }}
        >
          <div
            className="top-footer"
            style={{
              paddingVertical: '20 !important',
              width: '75%',
            }}
          >
            <img src={images?.pluralcodeLogo} className="company-logo" alt="" />
            <form className="footer-signup-form">
              <h5 className="tc-primary head-font fs-28 pb-2 pb-md-4">
                Plural Code Technologies
              </h5>
              <h5 className="tc-primary head-font fs-16 pb-2 pb-md-4">
                A-1/129, Ground Floor, Sector - 17,
                <br />
                <br />
                Rohini, Delhi 110089, India
              </h5>
              <h5 className="tc-primary head-font fs-16 pb-2 pb-md-4">
                <img src={images?.email} className="icon" alt="" />
                <a
                  className="tc-primary head-font fs-16 pb-2 pb-md-4"
                  href="mailto:info@pluralcode.com"
                >
                  info@pluralcode.com
                </a>
              </h5>
              {/* <h5 className="tc-primary head-font fs-16 pb-2 pb-md-4">
                <img src={images?.telephone} className="icon" alt="" />
                <a
                  className="tc-primary head-font fs-16 pb-2 pb-md-4"
                  href="telto:+91-9958920231"
                >
                  +91-9958920231
                </a>
              </h5>
              <h5 className="tc-primary head-font fs-16 pb-2 pb-md-4">
                <img src={images?.whatsapp} className="icon" alt="" />
                <a
                  className="tc-primary head-font fs-16 pb-2 pb-md-4"
                  href="https://api.whatsapp.com/send?phone=+919958920231&text=Hello%20"
                >
                  +91-9958920231
                </a>
              </h5> */}
              <h5 className="tc-primary head-font fs-16 pb-2 pb-md-4">
                <img src={images?.linkedinDark} className="icon" alt="" />
                <a
                  href="https://www.linkedin.com/company/plural-code-technologies"
                  target="_blank"
                  className="tc-primary head-font fs-16 pb-2 pb-md-4"
                >
                  Let's connect!
                </a>
              </h5>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
}

import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "../assets/stylesheets/bootstrap.min.css";
import "../assets/stylesheets/style.css";
import "../assets/stylesheets/responsive.css";

export default function PortfolioNew() {
  const location = useLocation();

  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec president-sec pt-0">
          <div className="px-3">
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div className="col-md-6 pt-4 pt-lg-0">
                <div className="d-flex flex-column align-items-center text-center">
                  <Link
                    className="tc-primary-light-bordered text-uppercase fs-12"
                    to={"/portfolios"}
                  >
                    PORTFOLIOS
                  </Link>
                  <h1 className="tc-primary head-font fs-44 py-3">
                    {location?.state?.item?.title}
                  </h1>
                  <p className="head-font tc-primary">
                    {location?.state?.item?.subTitle}
                  </p>
                </div>
                <hr className="hr-double" />
              </div>
            </div>
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <img
                src={location?.state?.item?.image}
                className="img-fluid"
                style={{ backgroundSize: "contain", borderRadius: 7 }}
                alt=""
              />
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5 pt-4">
            <div className="row justify-content-center pt-0 pt-lg-4">
              <div className="col-lg-12">
                <br></br>
                <br></br>

                {location?.state?.item?.heading && (
                  <>
                    <h2 className="tc-primary head-font py-3">
                      <strong>About:</strong>
                    </h2>
                    <p
                      className="tc-primary fs-26 py-2"
                      style={{ justifyContent: "center", textAlign: "justify" }}
                    >
                      {location?.state?.item?.heading}
                    </p>
                  </>
                )}
                <br></br>
                <h2 className="tc-primary head-font py-3">
                  <strong>Business Situation:</strong>
                </h2>

                <p
                  className="tc-primary fs-26 py-2"
                  style={{ justifyContent: "center", textAlign: "justify" }}
                >
                  {location?.state?.item?.problem}
                </p>
                <br></br>
                <h2 className="tc-primary head-font py-3">
                  <strong>Solution:</strong>
                </h2>
                <p
                  className="tc-primary fs-26 py-2"
                  style={{ justifyContent: "center", textAlign: "justify" }}
                >
                  {location?.state?.item?.solution}
                </p>
                <br></br>
                <h2 className="tc-primary head-font py-3">
                  <strong>Result:</strong>
                </h2>

                <br></br>

                <ol className="simple-list tc-primary">
                  {location?.state?.item?.results.map((item) => {
                    return (
                      <li style={{ fontWeight: "bold" }} key={item}>
                        <p
                          className="tc-primary fs-26"
                          style={{
                            justifyContent: "center",
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                      </li>
                    );
                  })}
                </ol>

                {location?.state?.item?.url && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h2 className="tc-primary head-font py-3">
                      <strong>URL:</strong>
                    </h2>

                    <a href={location?.state?.item?.url} target="blank">
                      <h2 className="tc-primary head-font py-3">
                        <strong>
                          &nbsp;&nbsp;
                          {location?.state?.item?.title}
                        </strong>
                      </h2>
                    </a>
                  </div>
                )}
              </div>
              <div className="col-lg-3 ps-lg-5 d-lg-block d-none"></div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

export default function PortfolioCard({ item }) {
  const navigate = useNavigate();

  const toPortfolio = () => {
    if (item?.type === "new") {
      navigate(`/portfolio-n/${item?.name}`, { state: { item: item } });
    } else {
      navigate(`/portfolio/${item?.name}`, { state: { item: item } });
    }
  };

  return (
    <div className="col-lg-4 col-md-6">
      <div
        className="cards"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <a onClick={toPortfolio} style={{ cursor: "pointer" }}>
          <img
            src={item?.portfolioCard}
            className="img-fluid"
            style={{
              height: 280,
              objectFit: "contain",
              alignSelf: "center",
            }}
            alt=""
          />
          <h6 className="fs-12 tc-primary py-2 fw-bold text-uppercase">
            {item?.title}
          </h6>
        </a>
      </div>
    </div>
  );
}
